<template>
  <div class="content_body" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form :inline="true" size="small" @keyup.enter.native="handleSearch">
            <el-form-item label="收支名称">
              <el-input v-model="name" @clear="handleSearch" placeholder="输入收支名称搜索" clearable></el-input>
            </el-form-item>
            <el-form-item label="有效性">
              <el-select @change="handleSearch" @clear="handleSearch" v-model="active" placeholder="请选择" clearable>
                <el-option label="是" :value="true"></el-option>
                <el-option label="否" :value="false"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch" v-prevent-click>搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button type="primary" size="small" @click="showAddDialog">新增</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="martp_10">
      <el-table size="small" :data="tableData">
        <el-table-column prop="Name" label="收支名称"></el-table-column>
        <el-table-column label="移动" min-width="180px">
          <template slot-scope="scope">
            <el-button size="small" type="primary" circle icon="el-icon-upload2" @click="upOneClick(scope.row, scope.$index)" v-prevent-click :disabled="scope.$index==0"></el-button>
            <el-button size="small" type="primary" circle icon="el-icon-top" @click="upClick(scope.row, scope.$index)" v-prevent-click :disabled="scope.$index==0"></el-button>
            <el-button size="small" type="primary" circle icon="el-icon-bottom" @click="downClick(scope.row, scope.$index)" v-prevent-click :disabled="scope.$index ==tableData.length-1"></el-button>
            <el-button size="small" type="primary" circle icon="el-icon-download" @click="downOneClick(scope.row, scope.$index)" v-prevent-click :disabled="scope.$index ==tableData.length-1"></el-button>
          </template>
        </el-table-column>
        <el-table-column prop="Active" label="有效性" :formatter="formatStatus"></el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="showEditDialog(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!--弹窗-->
    <el-dialog :title="isAdd ? '新增收支方式' : '编辑收支方式'" :visible.sync="dialogVisible" width="550px">
      <div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" size="small" @submit.native.prevent>
          <el-form-item label="收支名称" prop="Name">
            <el-input clearable v-model="ruleForm.Name"></el-input>
          </el-form-item>
          <el-form-item label="是否有效" v-if="!isAdd">
            <el-radio-group v-model="ruleForm.Active">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" size="small" @click="addSubmit" :loading="modalLoading" v-prevent-click>保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/iBeauty/Basic/payments";

export default {
  name: "payments",
  data() {
    return {
      loading: false,
      modalLoading: false,
      dialogVisible: false,
      isAdd: true,
      active: true,
      name: "",
      tableData: [],
      ruleForm: {
        Name: "",
        Active: true,
      },
      rules: {
        Name: [{ required: true, message: "请输入收支名称", trigger: "blur" }],
        Active: [
          { required: true, message: "请选择有效性", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    // //状态显示转换
    formatStatus: function (row) {
      return row.Active ? "有效" : "无效";
    },
    // 数据显示
    handleSearch: function () {
      let that = this;
      that.loading = true;
      var params = {
        Name: that.name,
        Active: that.active,
      };
      API.all(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.tableData = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    // 移动首部
    upOneClick: function (row) {
      var that = this;
      that.movePaymetData(row.ID, "");
    },
    // 移动尾部
    downOneClick: function (row, index) {
      var that = this;
      var tabIndex = that.tableData.length;
      var beforeId = "";
      if (index < tabIndex - 1) {
        beforeId = that.tableData[tabIndex - 1].ID;
      }
      that.movePaymetData(row.ID, beforeId);
    },
    // 向上
    upClick: function (row, index) {
      var that = this;
      var beforeId = "";
      if (index > 1) {
        beforeId = that.tableData[index - 2].ID;
      }
      that.movePaymetData(row.ID, beforeId);
    },
    // 向下
    downClick: function (row, index) {
      var that = this;
      var beforeId = "";
      if (index + 1 != that.tableData.length) {
        beforeId = that.tableData[index + 1].ID;
      }
      that.movePaymetData(row.ID, beforeId);
    },
    // 移动顺序
    movePaymetData: function (moveId, beforeId) {
      var that = this;
      that.loading = true;
      var params = {
        MoveID: moveId,
        BeforeID: beforeId,
      };
      API.move(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              message: "移动成功",
              duration: 2000,
            });
            that.handleSearch();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 新增
    showAddDialog: function () {
      var that = this;
      that.dialogVisible = true;
      that.isAdd = true;
      that.ruleForm = {
        Name: "",
      };
    },
    // 跳转
    showEditDialog: function (row) {
      var that = this;
      that.dialogVisible = true;
      that.isAdd = false;
      that.ruleForm = Object.assign({}, row);
    },
    //模态窗数据
    addSubmit: function () {
      let that = this;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          that.modalLoading = true;
          let para = Object.assign({}, that.ruleForm);
          if (that.isAdd) {
            API.create(para)
              .then(function (res) {
                if (res.StateCode === 200) {
                  that.$message.success({
                    showClose: true,
                    message: "新增成功",
                    duration: 2000,
                  });
                  that.handleSearch();
                  that.$refs["ruleForm"].resetFields();
                } else {
                  that.$message.error({
                    showClose: true,
                    message: res.Message,
                    duration: 2000,
                  });
                }
              })
              .finally(function () {
                that.dialogVisible = false;
                that.modalLoading = false;
              });
          } else {
            var params = {
              ID: para.ID,
              Name: para.Name,
              Active: para.Active,
            };
            API.update(params)
              .then(function (res) {
                if (res.StateCode === 200) {
                  that.$message.success({
                    showClose: true,
                    message: "编辑成功",
                    duration: 2000,
                  });
                  that.$refs["ruleForm"].resetFields();
                  that.handleSearch();
                } else {
                  that.$message.error({
                    showClose: true,
                    message: res.Message,
                    duration: 2000,
                  });
                }
              })
              .finally(function () {
                that.dialogVisible = false;
                that.modalLoading = false;
              });
          }
        }
      });
    },
  },
  mounted() {
    var that = this;
    that.handleSearch();
  },
};
</script>

<style scoped lang="scss">
</style>
